<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <h4 class="text-center text-primary">
      <strong>{{
        $t(resources.DownloadCertification.i18n) ||
          resources.DownloadCertification.name
      }}</strong>
    </h4>
    <vs-row vs-type="flex" vs-justify="center" class="mb-4">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <vs-button
          color="primary"
          type="filled"
          class="mt-2 mb-base"
          icon-pack="feather"
          icon="icon-download"
          @click="downloadPDF"
          >{{
            $t(resources.DownloadPDF.i18n) || resources.DownloadPDF.name
          }}</vs-button
        >
      </vs-col>
    </vs-row>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-4">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Language.i18n) || resources.Language.name
            }}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="languageOption"
              :reduce="language => language.internationalStandard"
              label="name"
              v-model="language"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="getBlog()">
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>
      <div id="knowledge-base-page">
        <!-- JUMBOTRON -->
        <!-- <div class="knowledge-base-jumbotron mb-4">
        <vs-input
          icon-no-border
          :placeholder="$t(resources.Search.i18n) || resources.Search.name"
          v-model="searchQuery"
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6"
          v-on:input="filtered(`${searchQuery}`)"
          :hint="`${searchQuery}`"
        />
      </div> -->

        <vs-collapse type="border" class="mt-4 mb-4">
          <vs-collapse-item v-for="(category, idx) in dataList" :key="idx">
            <div slot="header">{{ category[0].contentTypeName }}</div>
            <div class="vx-row">
              <div
                class="vx-col w-full md:w-1/3 sm:w-full mb-4"
                v-for="item in category"
                :key="item.id"
              >
                <vx-card class="text-center cursor-pointer">
                  <vs-icon
                    v-if="item.attachmentType == 'MP4'"
                    icon-pack="material-icons"
                    icon="movie"
                    size="large"
                  ></vs-icon>
                  <vs-icon
                    v-else-if="item.attachmentType == 'PDF'"
                    icon-pack="material-icons"
                    icon="picture_as_pdf"
                    size="large"
                    color="danger"
                  ></vs-icon>
                  <img
                    v-else
                    :src="item.attachementUrl"
                    alt="graphic"
                    width="200"
                    class="mx-auto mb-4"
                  />
                  <vs-button
                    color="success"
                    type="line"
                    icon-pack="feather"
                    icon="icon-download-cloud"
                    size="large"
                    @click="openDocument(`${item.attachementUrl}`)"
                  ></vs-button>
                  <h4 class="mb-2">
                    <strong>{{ item.title }}</strong>
                  </h4>
                  <div v-html="item.body"></div>
                </vx-card>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";
import vSelect from "vue-select";
import jspdf from "jspdf";
import logo_base64 from "@/assets/images/logo/logo_base64.js";
import signed_base64 from "@/assets/images/logo/signed_base64.js";
import "jspdf-autotable";
import moment from "moment";

export default {
  data() {
    return {
      resources: resources,
      searchQuery: "",
      dataList: [],
      language: "ES",
      languageOption: [],
      imgLogo: logo_base64.image,
      signedLogo: signed_base64.image
    };
  },
  async created() {
    await this.getBlog();
    await this.getLanguage();
  },
  computed: {},
  methods: {
    //Download PDF with commissions summary
    downloadPDF() {
      var doc = new jspdf();
      let user = JSON.parse(localStorage.getItem("userInfo"));

      const _img = this.imgLogo;
      const _signed = this.signedLogo;
      let pdfName = "CertificationVisionbiz.pdf";

      doc.addImage(_img, "png", 7, 5, 80, 20, undefined, "none");
      doc.setFontSize(12);
      doc.setFontType("bold");

      doc.text(23, 35, `${moment(new Date()).format("ll")}.`);
      doc.text(80, 60, "A QUIEN PUEDA INTERESAR.");

      doc.setFontType("normal");

      doc.text(23, 85, `Hacemos constar que:`);
      doc.setFontType("bold");
      doc.text(
        65,
        85,
        ` ${user.name
          .toUpperCase()
          .substring(0, 20)} ${user.lastName.toUpperCase().substring(0, 20)},`
      );
      doc.setFontType("normal");
      doc.text(
        23,
        90,
        `con documento de identidad ________________, es un IBO (Independent Business`
      );
      doc.text(
        23,
        95,
        `Owner), desde ${moment(user.affiliationDate).format(
          "YYYY-MM-DD"
        )} y a la fecha se encuentra activo y responsable de sus`
      );
      doc.text(23, 100, `actividades.`);
      doc.text(
        23,
        110,
        `Como IBO tiene la posibilidad de participar en diferentes áreas de negocios, tales co-`
      );
      doc.text(
        23,
        115,
        `mo lo son: el área inmobiliaria y de seguros, para las cuales debe certificarse en pro`
      );
      doc.text(23, 120, `de promover nuestros productos y servicios.`);
      doc.text(
        23,
        130,
        `A su vez participan en el área de turismo donde pueden comercializar productos y ser-`
      );
      doc.text(
        23,
        135,
        `vicios de nuestros diferentes proveedores en esta área y a la vez obtienen precios pre-`
      );
      doc.text(
        23,
        140,
        `ferenciales alrededor del mundo. Además de estos beneficios, tienen la oportunidad de`
      );
      doc.text(
        23,
        145,
        `de asistir a diferentes eventos realizados por la compañía en distintos países.`
      );

      doc.setFontType("bold");
      doc.text(60, 165, "DE ANTEMANO AGRADECEMOS SU ATENCIÓN.");
      doc.setFontType("normal");
      doc.text(23, 190, "Atentamente,");
      doc.addImage(_signed, "png", 23, 192, 33, 28, undefined, "none");
      doc.setFontType("bold");
      doc.text(23, 220, "_______________________");
      doc.text(23, 225, "FELIPE VILLEGAS");
      doc.text(23, 230, "CEO");
      doc.text(23, 235, "VISIONBIZ");

      doc.save(pdfName);
    },

    filtered(searchQuery) {
      if (searchQuery.length >= 3) {
        this.dataList = this.dataList.filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      } else if (searchQuery == "") {
        this.getBlog();
      }
    },

    async getBlog() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: 1
          },
          page: 1,
          rows: 100,
          language: this.language.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            let _data = result.data.data.reduce(function(rv, x) {
              (rv[x["contentTypeName"]] = rv[x["contentTypeName"]] || []).push(
                x
              );
              return rv;
            }, {});

            this.dataList = _data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getLanguage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Language`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.languageOption = [];
          } else {
            this.languageOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    openDocument(url) {
      window.open(url, this.$i18n.t("OpenDocument"));
    }
  },
  components: { vSelect }
};
</script>
